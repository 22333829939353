.about-us-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px 0 0 0;
  }
  .about-us-parent {
    max-width: 1000px;
    width: 70%;
  }
  .about-us-card {
    display: flex;
    width: 100%;
    box-shadow: 0 0px 20px -2px #1f2235;
  }
  .about-us-profile {
    width: 50%;
    background-image: url("../../assets/AboutUs/me.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .about-us-details {
    width: 48%;
    text-align: justify;
    padding: 30px;
  }
  .about-us-description {
    font-size: 12px;
  }
  .about-us-highlights {
    margin: 30px 0;
  }
  .highlght-heading {
    font-family: "Poppins Bold";
    font-size: 14px;
    margin: 0 0 5px 0;
  }
  .highlight {
    display: -webkit-box;
    align-items: center;
    margin: 5px 0;
    font-size: 12px;
  }
  .highlight-blob {
    height: 8px;
    width: 8px;
    margin: 0 10px 0 0;
    background-color: var(--dark-orange);
    border-radius: 50%;
  }
  .about-us-options{
    /* margin-left: 20px; */
    /* position: relative; */
    /* left: 10%; */
  }
  .about-us-options .highlighted-btn {
    margin: 0 0 0 30px;
  }
  
  /*IPAD PRO RESPONSIVENESS*/
  @media only screen and (max-width: 1110px) {
    .about-us-parent {
      width: 88%;
    }
  }
  @media only screen and (max-width: 898px) {
    .about-us-profile {
      display: none;
    }
    .about-us-details {
      width: 100%;
    }
  }
  @media only screen and (max-width: 466px) {
    .about-us-options {
      display: flex;
      flex-direction: column;
    }
    .about-us-options .highlighted-btn {
      margin: 24px 0 0 0 !important;
    }
    .about-us-options button {
      width: 100%;
    }
  }