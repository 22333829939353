.fixed-footer-container {
  /* position: ; */
  bottom: 0;
  text-align: center;
  width: 100%;
  height: 40px;
  background: rgb(51, 64, 13);
  background: linear-gradient(
    150deg,
    rgba(0, 64, 13, 1) 0%,
    rgba(112, 130, 56, 1) 90%
  );
  justify-content: center;
  padding: 9px;
}
/* 
  .fixed-footer-parent{
    
    padding: 3px; 
  } */

.fixed-footer-text {
  color: white;
}

@media only screen and (max-width: 1110px) {
  .fixed-footer-container {
    font-size: 12px;
  }
}
@media only screen and (max-width: 910px) {
  .fixed-footer-container {
    font-size: 12px;
  }
}
@media only screen and (max-width: 810px) {
  .fixed-footer-container {
    font-size: 12px;
  }
}
@media only screen and (max-width: 375px) {
  .fixed-footer-container {
    font-size: 10px;
  }
}
/* @media only screen and (min-width: 475px) {
    .scroll-container {
      margin-bottom: 10px;
    }
  }
  .btn-scroll {
    width: 50px;
    height: 50px;
    color: aliceblue;
    font-size: 24px;
    background-color: var(--dark-orange);
    border-radius: 10px;
    border: none;
  }
  .btn-scroll:hover{
    background-color: black;
  }
  .btn-scroll i:hover {
    transform: rotate(360deg);
    color: aliceblue;
  }
  
  .btn-scroll i {
    -webkit-animation: mover 0.5s infinite alternate;
    animation: mover 0.5s infinite alternate;
  }
  @keyframes mover {
    100% {
      transform: translateY(-5px);
    }
    0% {
      transform: translateX(0px);
    }
  } */
