.career-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0 0 0;
}
.career-parent {
  max-width: 1000px;
  width: 70%;
}
.career-card {
  display: flex;
  width: 100%;
  box-shadow: 0 0px 20px -2px #1f2235;
}
.career-profile {
  width: 50%;
  background-image: url('../../assets/Career/bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.career-details {
  width: 48%;
  text-align: justify;
  padding: 30px;
}
.career-description {
  font-size: 12px;
}
.career-highlights {
  margin: 80px 0;
}
.highlght-heading {
  font-family: 'Poppins Bold';
  font-size: 14px;
  margin: 0 0 5px 0;
}
.highlight {
  /* display: flex;
    align-items: center;
    margin: 5px 0;
    font-size: 12px; */
}
.highlight-blob {
  /* height: 12px;
    width: 12px;
    margin: 0 10px 0 0;
    background-color: var(--dark-orange);
    border-radius: 50%; */
}
.career-options .highlighted-btn {
  margin: 0 0 0 30px;
}

/* COMMENT */

form {
  width: auto;
  box-shadow: 0 0 2px 1px white;
  display: flex;
  color: #111;
  padding: 15px;
  justify-content: center;
  flex-direction: column;
  margin-top: -52px;
  opacity: 0.9;
  background-color: white;
  box-shadow: 0 0px 20px -2px #1f2235;
  border-radius: 12px;
}

form p {
  text-align: center;
  margin-bottom: 10px;
  color: var(--dark-orange);
  letter-spacing: 0.1rem;
  font-family: 'Poppins Bold';
}

form label {
  margin-bottom: 5px;
  color: #111;
  letter-spacing: 0.2rem;
}

.highlight-heading form input {
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0);
  outline: none;
  background-color: #dbdbdd;
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  margin-bottom: 22px;
  transition: 0.3s;
  border-radius: 10px;
}
form input:focus {
  border: 2px solid rgba(30, 85, 250, 0.47);
  background-color: #fff;
}

form button {
  margin-top: 15px;
  padding: 5px;
  outline: none;
  width: 900px;
  color: rgb(230, 227, 227);
  border: 2px solid green;
  font-size: 12px;
  border-radius: 19px;
  background-color: #1f2235;
  font-size: 18px;
  width: 160px;
  align-items: center;
  justify-content: center;
}

form button:hover {
  background-color: #1f2235;
  transition: all ease 1s;
  border: 2px solid #1f2235;
}

button .load {
  width: 100%;
}

button .load img {
  width: 60px;
  color: green;
}
.col i {
  padding: 7px;
  color: #fff;
}
.col i:hover {
  transform: scale(1.5);
}

/*IPAD PRO RESPONSIVENESS*/
@media only screen and (max-width: 1110px) {
  .career-parent {
    width: 88%;
  }
}
@media only screen and (max-width: 898px) {
  .career-profile {
    display: none;
  }
  .career-details {
    width: 100%;
  }
}
@media only screen and (max-width: 466px) {
  .career-options {
    display: flex;
    flex-direction: column;
  }
  .career-options .highlighted-btn {
    margin: 24px 0 0 0 !important;
  }
  .career-options button {
    width: 100%;
  }
}
